import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveFilterService {
  private storageKey = 'activeFilters';
  private activeFiltersSubject = new BehaviorSubject<{
    company: boolean;
    location: boolean;
    contract: boolean;
  }>({
    company: false,
    location: false,
    contract: false
  });

  constructor() {
    this.loadFromLocalStorage();
  }

  private loadFromLocalStorage() {
    const storedValue = localStorage.getItem(this.storageKey);
    if (storedValue) {
      this.activeFiltersSubject.next(JSON.parse(storedValue));
    }
  }

  private saveToLocalStorage() {
    localStorage.setItem(this.storageKey, JSON.stringify(this.activeFiltersSubject.value));
  }

  setActive(type: 'company' | 'location' | 'contract', value: boolean) {
    const currentValue = this.activeFiltersSubject.value;
    currentValue[type] = value;
    this.activeFiltersSubject.next(currentValue);
    this.saveToLocalStorage();
  }

  getActive(type: 'company' | 'location' | 'contract') {
    return this.activeFiltersSubject.asObservable().pipe(
      map(filters => filters[type])
    );
  }

  getAllActive() {
    return this.activeFiltersSubject.asObservable();
  }
}